<template>
  <NuxtLoadingIndicator :color="$color" :height="5" />
  <div class="app relative h-full" :class="`theme-${$appConfig ? $theme : 1}`">
    <NuxtLayout>
      <NuxtPage />
      <ToastList />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import useLocale from "./composables/Common/useLocale";
import useGAnalytics from "~/composables/Common/useGAnalytics";
import { useOnline } from "@vueuse/core";
import ToastList from "~/components/common/ToastList.vue";
import { useCategoriesStore } from "./store/Features/ProductManagement/categoriesStore";
import { useHelpPages } from "./composables/features/Footer/useHelpPages";
import useHeaderMenuInfo from "./composables/features/Header/useHeaderMenuInfo";
import { useFooterMenu } from "./composables/features/Footer/useFooterMenu";

const isOnline = useOnline();
const { initLocale } = useLocale();
const { getGAnalyticsScript } = useGAnalytics();
const { fetchCategories } = useCategoriesStore();
const { fetchPages } = useHelpPages();
const { getBundles, getBrands } = useHeaderMenuInfo();
const { getFooterInfo } = useFooterMenu();

useHead(() => ({
  script: getGAnalyticsScript(),
}));

onMounted(() => {
  initLocale();

  fetchPages();
  getBrands();
  getBundles();
  fetchCategories({ include_media: true });
  getFooterInfo();

  watchEffect(() => {
    if (!isOnline.value) {
      throw createError({
        statusCode: 404,
        message: "You are not connected to the internet!",
        fatal: true,
      });
    }
  });
});
</script>

<style>
* {
  --main: v-bind("$color");
  --swiper-scrollbar-drag-bg-color: var(--main);
  --swiper-navigation-color: var(--main);
  --swiper-pagination-color: var(--main);
}
</style>
