import type { EventMap } from "~/types/Common/trackers";
import parsePriceString from "~/utils/ProductManagement/extractPrice";

export const eventsMap: {
  [K in keyof EventMap]: {
    getPayload: (...args: EventMap[K]["params"]) => EventMap[K]["trackers"];
  };
} = {
  pageView: {
    getPayload: (pageName, pagePath) => ({
      meta: {
        event: "PageView",
      },
      gtm: {
        event: "page_view",
        category: "page",
        action: "view",
        label: pageName,
        noninteraction: true,
        page_path: pagePath,
        page_name: pageName,
      },
    }),
  },
  addToCart: {
    getPayload: (id, type, price) => {
      const parsedPrice = parsePriceString(price);
      return {
        meta: {
          event: "AddToCart",
          payload: {
            content_ids: [id],
            content_type: type,
            currency: parsedPrice?.currency,
            value: parsedPrice?.value,
          },
        },
        gtm: {
          event: "add_to_cart",
          category: "cart",
          action: "click",
          label: "add to cart",
          noninteraction: false,
          currency: parsedPrice?.currency,
          value: parsedPrice?.value,
          items: [{ id, type }],
        },
      };
    },
  },
  checkout: {
    getPayload: (order) => {
      const price = parsePriceString(order?.pricing.total_price);
      const metaItems = [
        ...(order?.products ?? []),
        ...(order?.variants ?? []),
      ]?.reduce<any>(
        (acc, item) => [
          ...acc,
          {
            id: item.id,
            name: item.title,
            quantity: item.quantity,
          },
        ],
        [],
      );

      return {
        meta: {
          event: "InitiateCheckout",
          payload: {
            contents: metaItems,
            currency: price?.currency,
            value: price?.value,
          },
        },
        gtm: {
          event: "begin_checkout",
          category: "cart",
          action: "click",
          label: "begin checkout",
          noninteraction: false,
          currency: price?.currency,
          value: price?.value,
          items: {
            products: order?.products ?? [],
            bundles: order?.bundles ?? [],
            variants: order?.variants ?? [],
          },
        },
      };
    },
  },
  purchase: {
    getPayload: (order) => {
      const price = parsePriceString(order?.pricing.total_price);
      const shippingPrice = parsePriceString(order?.pricing.delivery_fees);
      const metaItems = [
        ...(order?.products ?? []),
        ...(order?.variants ?? []),
      ]?.reduce<any>(
        (acc, item) => [
          ...acc,
          {
            id: item.id,
            name: item.title,
            quantity: item.quantity,
          },
        ],
        [],
      );

      return {
        meta: {
          event: "Purchase",
          payload: {
            contents: metaItems,
            currency: price?.currency,
            value: price?.value,
          },
        },
        gtm: {
          event: "purchase",
          category: "cart",
          action: "click",
          label: "purchase",
          noninteraction: false,
          currency: price?.currency,
          value: price?.value,
          shipping: shippingPrice?.value,
          items: {
            products: order?.products ?? [],
            bundles: order?.bundles ?? [],
            variants: order?.variants ?? [],
          },
        },
      };
    },
  },
  login: {
    getPayload: () => ({
      gtm: {
        event: "login",
        category: "Auth",
        action: "click",
        label: "login",
        noninteraction: false,
      },
    }),
  },
  register: {
    getPayload: () => ({
      meta: {
        event: "CompleteRegistration",
      },
      gtm: {
        event: "sign_up",
        category: "Auth",
        action: "click",
        label: "register",
        noninteraction: false,
      },
    }),
  },
  viewContent: {
    getPayload: (product) => ({
      meta: {
        event: "ViewContent",
        payload: {
          content_type: "product",
          content_ids: [product.id],
          content_name: product.title,
        },
      },
    }),
  },
  wishlist: {
    getPayload: (productId) => ({
      meta: {
        event: "AddToWishlist",
        payload: {
          content_ids: [productId],
          content_name: "product",
        },
      },
    }),
  },
  contact: {
    getPayload: () => ({
      meta: {
        event: "Contact",
      },
    }),
  },
  paymentInfo: {
    getPayload: () => ({
      meta: {
        event: "AddPaymentInfo",
      },
    }),
  },
};
