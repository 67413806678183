import { BaseResponse } from "~/types/Common/APIs";
import { Page } from "~/types/Footer/HelpPages";

export const useHelpPages = () => {
  const pagesInfo = useState<Page[]>("help-pages", () => []);

  const fetchPages = async () => {
    const { res } = await useApi<BaseResponse<Page[]>>(`/api/v1/pages`);

    pagesInfo.value = res?.data || [];
    return res?.data || [];
  };

  const helpSectionPages = computed(() => {
    return pagesInfo.value.filter((page: Page) => page.key !== "about-us");
  });

  return {
    pagesInfo,
    helpSectionPages,
    fetchPages,
  };
};
