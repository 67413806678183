import { BaseResponse } from "~/types/Common/APIs";
import { FooterInfo, SocialLink } from "~/types/Footer/FooterInfo";

export const useFooterMenu = () => {
  const footerInfo = useState<FooterInfo[]>("footer-info", () => []);

  const getFooterInfo = async () => {
    const { res } =
      await useApi<BaseResponse<FooterInfo[]>>(`/api/v1/contact-info`);

    if (res?.data) {
      footerInfo.value = res.data;
    }
  };

  const tax = computed<FooterInfo[]>(() => {
    const taxInfo = footerInfo.value.find(
      (item: { type: string }) => item.type === "tax registration ID",
    );
    return taxInfo ? [taxInfo] : [];
  });

  const socialLinks = computed(() => {
    return footerInfo.value
      .filter((item) => item.type === "social media")
      .map((item) => ({
        title: item.title,
        content: item.content,
        type: item.type,
        image: item.image,
      })) as SocialLink[];
  });

  return {
    footerInfo,
    tax,
    socialLinks,
    getFooterInfo,
  };
};
