import type {
  AddToCartParams,
  CheckoutParams,
  EventMap,
  PageViewParams,
  PurchaseParams,
  ViewContentParams,
  WishlistParams,
} from "~/types/Common/trackers";
import { eventsMap } from "~/constants/trackers";

export default function useTrackEvents() {
  const nuxtApp = useNuxtApp();

  const gtmEnabled = computed(() =>
    nuxtApp.$appConfig?.integrations.includes("google_tag_integration"),
  );

  const metaPixelEnabled = computed(() =>
    nuxtApp.$appConfig?.integrations.includes("facebook_pixel_integration"),
  );

  const trackEvent = <T extends keyof EventMap>(
    eventName: T,
    ...args: EventMap[T]["params"]
  ) => {
    const payload = eventsMap[eventName].getPayload(...args);

    if (gtmEnabled.value && "gtm" in payload) {
      if (
        payload.gtm.event === "page_view" &&
        payload.gtm.page_name &&
        payload.gtm.page_path
      ) {
        nuxtApp.$Gtm?.trackView(payload.gtm.page_name, payload.gtm.page_path);
      } else {
        nuxtApp.$Gtm?.trackEvent(payload.gtm);
      }
    }

    if (metaPixelEnabled.value && "meta" in payload) {
      //@ts-ignore
      nuxtApp.$fbq("track", payload.meta.event, payload.meta.payload);
    }
  };

  const trackPageView = (...args: PageViewParams) =>
    trackEvent("pageView", ...args);
  const trackLogin = () => trackEvent("login");
  const trackRegistration = () => trackEvent("register");
  const trackAddToCart = (...args: AddToCartParams) =>
    trackEvent("addToCart", ...args);
  const trackInitCheckout = (...args: CheckoutParams) =>
    trackEvent("checkout", ...args);
  const trackPurchase = (...args: PurchaseParams) =>
    trackEvent("purchase", ...args);
  const trackAddToWishlist = (...args: WishlistParams) =>
    trackEvent("wishlist", ...args);
  const trackViewContent = (...args: ViewContentParams) =>
    trackEvent("viewContent", ...args);
  const trackContact = () => trackEvent("contact");
  const trackPaymentInfo = () => trackEvent("paymentInfo");

  return {
    gtmEnabled,
    metaPixelEnabled,
    trackEvent,
    trackPageView,
    trackLogin,
    trackRegistration,
    trackAddToCart,
    trackInitCheckout,
    trackPurchase,
    trackAddToWishlist,
    trackViewContent,
    trackContact,
    trackPaymentInfo,
  };
}
