import useTrackEvents from "~/composables/Common/useTrackEvents";

export default defineNuxtRouteMiddleware((to, from) => {
  const { gtmEnabled, metaPixelEnabled, trackPageView } = useTrackEvents();

  const PAGES_TO_TRACK = ["cart", "checkout", "register", "index", "products"];

  // prevent track initial load
  // prevent track same page multiple times
  if (from.name && from.name !== to.name) {
    // at least one of the tracks are enabled
    if (gtmEnabled.value || metaPixelEnabled.value) {
      if (metaPixelEnabled.value) {
        // page name is included in PAGES_TO_TRACK
        if (PAGES_TO_TRACK.includes(to.name?.toString() ?? "")) {
          trackPageView(to.name?.toString() ?? "", to.path);
        }
      }
    }
  }
});
