import {
  Brand,
  Bundle,
} from "~/types/InformativePages/Header/CategoryBundleBrand";

export default function useHeaderMenuInfo() {
  const brands = useState<Brand[]>(() => []);
  const bundles = useState<Bundle[]>(() => []);

  const nuxtApp = useNuxtApp();

  const getBrands = async () => {
    if (nuxtApp.$features?.brands) {
      const { res } = await useApi<{ data: Brand[] }>(`/api/v1/brands`);
      if (res?.data) brands.value = res.data;
    }
  };

  const getBundles = async () => {
    if (nuxtApp.$features?.bundles) {
      const { res } = await useApi<{ data: Bundle[] }>(`/api/v1/bundles`);
      if (res?.data) bundles.value = res.data;
    }
  };

  return {
    brands,
    bundles,
    getBrands,
    getBundles,
  };
}
