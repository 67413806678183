import { defineStore } from "pinia";
import { useUserStore } from "~/store/common/userStore";
import { OrdersResponse, OrdersState } from "~/types/UserManagement/order";

export const useOrdersStore = defineStore("orders-store", () => {
  const orders = ref<OrdersState>({
    activeOrders: null,
    inActiveOrders: null,
  });

  const userStore = useUserStore();

  const fetchActiveOrders = async (baseUrl: string) => {
    const { res } = await useApi<OrdersResponse>(
      `/api/v1/orders?filter[is_active]=1&include=variants,statuses,bundles,products`,
      {
        authToken: userStore.user?.token,
      },
      {
        baseUrl,
      },
    );
    orders.value.activeOrders = res?.data ? res.data : [];
  };

  const fetchInActiveOrders = async (baseUrl: string) => {
    const { res } = await useApi<OrdersResponse>(
      `/api/v1/orders?filter[is_active]=0&include=variants,statuses,bundles,products`,
      {
        authToken: userStore.user?.token,
      },
      {
        baseUrl,
      },
    );
    orders.value.inActiveOrders = res?.data ? res.data : [];
  };

  return {
    orders,
    fetchActiveOrders,
    fetchInActiveOrders,
  };
});
