import { defineStore } from "pinia";
import { useUserStore } from "~/store/common/userStore";
import { Product } from "~/types/ProductManagement/Product";
import { WishListResponse } from "~/types/ProductManagement/WishList";
import { useApi } from "~/composables/Common/useApi";
import useTrackEvents from "~/composables/Common/useTrackEvents";

export const useWishListStore = defineStore("wish-list", () => {
  const wishList = ref<Product[]>();

  const userStore = useUserStore();
  const { trackAddToWishlist } = useTrackEvents();

  const initWishlist = async (baseUrl?: string, value?: string) => {
    const { res } = await useApi<WishListResponse>(
      `/api/v2/wishlists?sort=${value ?? "recent"}`,
      {
        authToken: userStore.user?.token,
      },
      {
        baseUrl,
      },
    );

    wishList.value = res?.data;
  };

  const addToWishlist = async (productID: string) => {
    const { res } = await useApi<WishListResponse>("/api/v2/wishlists", {
      method: "POST",
      authToken: userStore.user?.token,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product_id: productID,
      }),
    });

    wishList.value = res?.data;
    trackAddToWishlist(productID);
    return true;
  };

  const removeFromWishlist = async (productID: string) => {
    const { res } = await useApi<WishListResponse>(
      `/api/v2/wishlists/${productID}`,
      {
        method: "DELETE",
        authToken: userStore.user?.token,
      },
    );
    wishList.value = res?.data;
    return true;
  };

  const inWishlist = (productID: string) => {
    if (!wishList.value) {
      wishList.value = [];
    }
    const index = wishList.value.findIndex((x) => x.id == productID);
    return index !== -1;
  };

  return {
    wishList,
    inWishlist,
    initWishlist,
    addToWishlist,
    removeFromWishlist,
  };
});
